// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-navigation {
  width: 100%;
}

.tab-buttons-container {
  display: flex;
}

.tabs-hr {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin-bottom: 1.5rem;
} `, "",{"version":3,"sources":["webpack://./src/components/molecules/tabNavigation/TabNavigation.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":[".tab-navigation {\n  width: 100%;\n}\n\n.tab-buttons-container {\n  display: flex;\n}\n\n.tabs-hr {\n  height: 1px;\n  width: 100%;\n  background-color: #e0e0e0;\n  margin-bottom: 1.5rem;\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
