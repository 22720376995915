var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../../organisms/navBar/navBar";
import PageHeader from "../../organisms/pageHeader/pageHeader";
import ObligationsTabs from "../../organisms/obligationsTabs/ObligationsTabs";
import { getStatsTileData } from "../../../redux/actions/dashboard";
import { prepareJobseekerObligationsData, prepareAdviserObligationsData, } from "./obligationsUtils";
import "./obligations.css";
/**
 * The Obligations Page
 * Shows client and adviser obligations with comparisons between
 * jobseeker and adviser perspectives
 */
function Obligations() {
    var dispatch = useDispatch();
    // Get data from Redux store
    var _a = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (state) { return state.rootReducer.dashboardReducer; }), obligations = _a.obligations, adviserObligations = _a.adviserObligations, adviser_clientObligations = _a.adviser_clientObligations, adviser_adviserObligations = _a.adviser_adviserObligations, status = _a.status, viewing_user_type = _a.viewing_user_type, logged_in_user_type = _a.logged_in_user_type;
    var username = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (state) { return state.rootReducer.userReducer.username; });
    var viewing_username = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (state) { return state.rootReducer.dashboardReducer.viewing_username; });
    // Fetch the dashboard data when component mounts
    useEffect(function () {
        console.log("Obligations component mounted, fetching dashboard data");
        // Check if we're viewing another user's dashboard
        var userToFetch = viewing_username || username;
        console.log("Username to fetch:", userToFetch);
        if (userToFetch) {
            dispatch(getStatsTileData(userToFetch));
            console.log("Dispatched getStatsTileData action for", userToFetch);
        }
        else {
            console.warn("Username is undefined, cannot fetch dashboard data");
        }
    }, [dispatch, username, viewing_username]);
    // Enhanced debug logging of raw data
    useEffect(function () {
        console.group("DETAILED OBLIGATIONS DEBUG LOG");
        console.log("--- DATA AVAILABILITY ---");
        console.log("Obligations data loaded:", {
            jobseekerData: (obligations === null || obligations === void 0 ? void 0 : obligations.length) || 0,
            adviserObligationsData: (adviserObligations === null || adviserObligations === void 0 ? void 0 : adviserObligations.length) || 0,
            adviser_clientObligations: (adviser_clientObligations === null || adviser_clientObligations === void 0 ? void 0 : adviser_clientObligations.length) || 0,
            adviser_adviserObligations: (adviser_adviserObligations === null || adviser_adviserObligations === void 0 ? void 0 : adviser_adviserObligations.length) || 0,
        });
        console.log("--- RAW DATA FROM BACKEND ---");
        console.log("1. Jobseeker Obligations (Your Obligations):", obligations);
        console.log("2. Adviser Obligations:", adviserObligations);
        console.log("3. Adviser's view of Client Obligations:", adviser_clientObligations);
        console.log("4. Adviser's view of Adviser Obligations:", adviser_adviserObligations);
        console.groupEnd();
    }, [
        obligations,
        adviserObligations,
        adviser_clientObligations,
        adviser_adviserObligations,
    ]);
    // Prepare data for the tables
    var jobseekerObligationsData = prepareJobseekerObligationsData(obligations, adviser_clientObligations);
    var adviserObligationsData = prepareAdviserObligationsData(adviserObligations, adviser_adviserObligations);
    // Log the processed data for comparison
    useEffect(function () {
        console.group("PROCESSED TABLE DATA");
        console.log("Your Obligations Table Data:", jobseekerObligationsData);
        console.log("Adviser Obligations Table Data:", adviserObligationsData);
        console.groupEnd();
    }, [jobseekerObligationsData, adviserObligationsData]);
    // Configuration for tabs
    var tabsConfig = [
        {
            title: "Your Obligations",
            label: "To what extent have you made the following commitment or obligation to your adviser?",
            data: jobseekerObligationsData,
        },
        {
            title: "Adviser Obligations",
            label: "To what extent has your adviser made the following commitment or obligation to you?",
            data: adviserObligationsData,
        },
    ];
    // Function to determine if adviser perspective data is available for a given tab
    var hasAdviserPerspective = function (tabIndex) {
        if (tabIndex === 0) {
            return adviser_clientObligations && adviser_clientObligations.length > 0;
        }
        else {
            return (adviser_adviserObligations && adviser_adviserObligations.length > 0);
        }
    };
    // Show loading state or content
    var isLoading = status === "loading";
    return (_jsxs("div", __assign({ className: "obligations" }, { children: [_jsx(NavBar, { viewing_user: viewing_user_type, logged_in_user: logged_in_user_type, viewing_username: viewing_username }), _jsxs("div", __assign({ className: "obligations-page-template-right" }, { children: [_jsx(PageHeader, { title: "Obligations" }), _jsx("div", __assign({ className: "flex flex-shrink-0 obligations-content" }, { children: isLoading ? (_jsx("div", __assign({ className: "w-full text-center py-8" }, { children: _jsx("p", { children: "Loading obligations data..." }) }))) : (_jsx(ObligationsTabs, { tabsConfig: tabsConfig, hasAdviserPerspective: hasAdviserPerspective })) }))] }))] })));
}
export default Obligations;
