var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../landing/landing.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { LogoutAction } from "../../../redux/actions/authentication";
import BrandHeader from "../../molecules/brandHeader/brandHeader";
import ContactForm from "./contactForm";
export default function Contact() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    // dispatch(getStatsTileData());
    var username = useSelector(function (state) { return state.rootReducer.userReducer.username; });
    // dispatch(getQuestionnaireHistory(username));
    //using selector hook to fetch data from the reducer
    var userData = useSelector(function (state) { return state.rootReducer.userReducer; });
    var numberOfAttempts = useSelector(function (state) {
        return state.rootReducer.questionnaireHistoryReducer.numberOfAttempts;
    });
    var complete = useSelector(function (state) { return state.rootReducer.questionnaireHistoryReducer.complete; });
    var role = useSelector(function (state) { return state.rootReducer.userReducer.role; });
    var handleSubmit = function () {
        navigate("/dashboard");
    };
    return (_jsxs("div", __assign({ className: "flex flex-col pt-4 bg-white min-h-[80vh]" }, { children: [_jsxs("header", __assign({ className: "flex gap-5 justify-between items-center px-[12%] text-base font-medium max-md:flex-wrap" }, { children: [_jsx(BrandHeader, {}), _jsxs("nav", __assign({ className: "flex gap-5 justify-between self-stretch px-11 py-6 backdrop-blur-[26.874555587768555px] leading-[114%] rounded-[35px] text-stone-900 max-md:flex-wrap max-md:px-5 max-md:max-w-full" }, { children: [_jsx("a", __assign({ href: "./", "aria-label": "Home" }, { children: "Home" })), _jsx("a", __assign({ href: "about", "aria-label": "About Us" }, { children: "About Us" })), _jsxs("div", __assign({ className: "flex flex-col flex-1 self-start text-indigo-600 whitespace-nowrap" }, { children: [_jsx("a", __assign({ href: "contact", "aria-label": "Contact Us" }, { children: "Contact Us" })), _jsx("hr", { className: "mt-2 h-px bg-indigo-600" })] }))] })), !userData.loginData ? (_jsx("button", __assign({ className: "justify-center self-stretch px-10 py-5 my-auto text-white whitespace-nowrap bg-indigo-600 rounded-3xl leading-[117%] max-md:px-5", onClick: handleSubmit }, { children: "Sign In" }))) : (_jsx("button", __assign({ className: "justify-center self-stretch px-10 py-5 my-auto text-white whitespace-nowrap bg-indigo-600 rounded-3xl leading-[117%] max-md:px-5", onClick: function () { return dispatch(LogoutAction()); } }, { children: "Logout" })))] })), _jsx("div", __assign({ className: "flex flex-col justify-center h-[100vh]" }, { children: _jsx(ContactForm, { className: "mx-auto" }) }))] })));
}
