import { jsx as _jsx } from "react/jsx-runtime";
import "../../styles/main.css";
import "../../styles/global.css";
import "../../styles/login.css";
/**
 * Input Field.
 * Props can take the type, name, id, value and placeholder which it will display inside the input field.
 * @param props
 * @returns
 */
export default function Input(props) {
    return (_jsx("input", { className: props.className, type: props.type, name: props.name, id: props.id, value: props.value, onChange: props.onChange, placeholder: props.placeholder, onInput: function (e) { return props.onInputChange(e.target.value); }, checked: props.isChecked, required: props.required }));
}
