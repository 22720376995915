var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getResponseValue } from "../../pages/obligations/obligationsUtils";
import "./ObligationsTableRow.css";
/**
 * ObligationsTableRow component for displaying a single row in the obligations table
 */
var ObligationsTableRow = function (_a) {
    var question = _a.question, jobseekerResponse = _a.jobseekerResponse, adviserResponse = _a.adviserResponse, difference = _a.difference;
    // Get numeric values for responses
    var jobseekerValue = getResponseValue(jobseekerResponse);
    var adviserValue = getResponseValue(adviserResponse);
    return (_jsxs("tr", __assign({ className: "table-row" }, { children: [_jsx("td", { children: question }), _jsxs("td", { children: [_jsx("span", __assign({ className: "response-text" }, { children: jobseekerResponse })), jobseekerValue > 0 && (_jsxs("span", __assign({ className: "response-value" }, { children: ["(", jobseekerValue, ")"] })))] }), _jsxs("td", { children: [_jsx("span", __assign({ className: "response-text" }, { children: adviserResponse })), adviserValue > 0 && (_jsxs("span", __assign({ className: "response-value" }, { children: ["(", adviserValue, ")"] })))] }), _jsx("td", { children: typeof difference === "number" ? difference : "-" })] })));
};
export default ObligationsTableRow;
