// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.obligations-table-container {
  margin-top: 1rem;
  width: 100%;
  overflow-x: auto;
}

.obligations-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.obligations-table thead {
  display: table-header-group;
}

.obligations-table th:first-child {
  border-top-left-radius: 0.25rem;
}

.obligations-table th:last-child {
  border-top-right-radius: 0.25rem;
}

.obligations-table tr:last-child td {
  border-bottom: none;
}

.adviser-perspective-alert {
  background-color: #e8f4f8;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #c2e0eb;
  margin-bottom: 1rem;
}

.adviser-perspective-alert p {
  margin: 0;
}

.no-data-message {
  text-align: center;
  padding: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .obligations-table-container {
    margin-top: 0.5rem;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/organisms/obligationsTable/ObligationsTable.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,iBAAiB;EACjB,qBAAqB;EACrB,wCAAwC;EACxC,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA,sBAAsB;AACtB;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".obligations-table-container {\n  margin-top: 1rem;\n  width: 100%;\n  overflow-x: auto;\n}\n\n.obligations-table {\n  width: 100%;\n  border-collapse: separate;\n  border-spacing: 0;\n  margin-bottom: 1.5rem;\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n  border: 1px solid #e0e0e0;\n}\n\n.obligations-table thead {\n  display: table-header-group;\n}\n\n.obligations-table th:first-child {\n  border-top-left-radius: 0.25rem;\n}\n\n.obligations-table th:last-child {\n  border-top-right-radius: 0.25rem;\n}\n\n.obligations-table tr:last-child td {\n  border-bottom: none;\n}\n\n.adviser-perspective-alert {\n  background-color: #e8f4f8;\n  padding: 10px 15px;\n  border-radius: 5px;\n  border: 1px solid #c2e0eb;\n  margin-bottom: 1rem;\n}\n\n.adviser-perspective-alert p {\n  margin: 0;\n}\n\n.no-data-message {\n  text-align: center;\n  padding: 1rem;\n}\n\n/* Responsive styles */\n@media (max-width: 768px) {\n  .obligations-table-container {\n    margin-top: 0.5rem;\n  }\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
