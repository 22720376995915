// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom nested ordered list numbering */
.custom-nested-list {
  list-style-type: none;
}

.custom-nested-list > li {
  position: relative;
  padding-left: 2em;
}

.custom-nested-list > li::before {
  position: absolute;
  left: 0;
  content: "9." counter(list-item) " ";
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/custom.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,oCAAoC;EACpC,iBAAiB;AACnB","sourcesContent":["/* Custom nested ordered list numbering */\n.custom-nested-list {\n  list-style-type: none;\n}\n\n.custom-nested-list > li {\n  position: relative;\n  padding-left: 2em;\n}\n\n.custom-nested-list > li::before {\n  position: absolute;\n  left: 0;\n  content: \"9.\" counter(list-item) \" \";\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
