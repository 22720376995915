// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-header {
color: white;
  text-align: left;
  padding: 1rem;
  font-weight: 600;
  border: 1px solid #2260df;
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Responsive styles */
@media (max-width: 768px) {
  .table-header {
    padding: 0.75rem 0.5rem;
    font-size: 0.9rem;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/atoms/tableHeader/TableHeader.css"],"names":[],"mappings":"AAAA;AACA,YAAY;EACV,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ;;AAEA,sBAAsB;AACtB;EACE;IACE,uBAAuB;IACvB,iBAAiB;EACnB;AACF","sourcesContent":[".table-header {\ncolor: white;\n  text-align: left;\n  padding: 1rem;\n  font-weight: 600;\n  border: 1px solid #2260df;\n  position: sticky;\n  top: 0;\n  z-index: 1;\n}\n\n/* Responsive styles */\n@media (max-width: 768px) {\n  .table-header {\n    padding: 0.75rem 0.5rem;\n    font-size: 0.9rem;\n  }\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
