var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import TabNavigation from "../../molecules/tabNavigation/TabNavigation";
import ObligationsTable from "../obligationsTable/ObligationsTable";
import "./ObligationsTabs.css";
/**
 * ObligationsTabs component for handling the tabbed interface for obligations
 */
var ObligationsTabs = function (_a) {
    var tabsConfig = _a.tabsConfig, hasAdviserPerspective = _a.hasAdviserPerspective;
    var _b = useState(0), activeTab = _b[0], setActiveTab = _b[1];
    return (_jsxs("div", __assign({ className: "tabs-overview" }, { children: [_jsx(TabNavigation, { tabs: tabsConfig, activeTab: activeTab, onTabChange: setActiveTab }), _jsx("div", __assign({ className: "tab-content active" }, { children: _jsx(ObligationsTable, { tableData: tabsConfig[activeTab].data, label: tabsConfig[activeTab].label, hasAdviserPerspective: hasAdviserPerspective(activeTab) }) }))] })));
};
export default ObligationsTabs;
