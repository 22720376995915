var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
export function FAQ(props) {
    var _a = useState(false), shown = _a[0], setShown = _a[1];
    return (_jsxs("div", __assign({ className: 'px-8 py-4 mx-auto w-[80%] bg-gray-200 rounded-xl my-4' }, { children: [_jsxs("div", __assign({ className: 'flex flex-row justify-between' }, { children: [_jsx("h1", __assign({ className: 'font-semibold' }, { children: props.question })), _jsx("button", __assign({ onClick: function () { return setShown(!shown); } }, { children: shown ?
                            _jsx("h1", __assign({ className: 'text-4xl' }, { children: "-" }))
                            : _jsx("h1", __assign({ className: 'text-4xl' }, { children: "+" })) }))] })), shown && _jsx("p", __assign({ className: 'mt-2 text-lg text-gray-700' }, { children: props.answer }))] })));
}
export default function FAQMulti(props) {
    return (_jsx("div", { children: props.data.map(function (faq, index) { return _jsx(FAQ, __assign({}, faq), index); }) }));
}
