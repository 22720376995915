// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-button {
  padding: 0.75rem 1.25rem;
  background: transparent;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--text-dark);
  position: relative;
  transition: all 0.3s ease;
}

.tab-button.active {
  color: var(--primary-color);
  font-weight: 600;
}

.tab-button.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}

/* Responsive styles */
@media (max-width: 768px) {
  .tab-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/atoms/tabButton/TabButton.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,WAAW;EACX,WAAW;EACX,sCAAsC;AACxC;;AAEA,sBAAsB;AACtB;EACE;IACE,uBAAuB;IACvB,iBAAiB;EACnB;AACF","sourcesContent":[".tab-button {\n  padding: 0.75rem 1.25rem;\n  background: transparent;\n  border: none;\n  font-size: 1rem;\n  font-weight: 500;\n  cursor: pointer;\n  color: var(--text-dark);\n  position: relative;\n  transition: all 0.3s ease;\n}\n\n.tab-button.active {\n  color: var(--primary-color);\n  font-weight: 600;\n}\n\n.tab-button.active:after {\n  content: '';\n  position: absolute;\n  bottom: -2px;\n  left: 0;\n  width: 100%;\n  height: 2px;\n  background-color: var(--primary-color);\n}\n\n/* Responsive styles */\n@media (max-width: 768px) {\n  .tab-button {\n    padding: 0.5rem 0.75rem;\n    font-size: 0.9rem;\n  }\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
