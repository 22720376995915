// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-overview {
  width: 100%;
}

.tab-content {
  display: block;
  width: 100%;
} `, "",{"version":3,"sources":["webpack://./src/components/organisms/obligationsTabs/ObligationsTabs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,WAAW;AACb","sourcesContent":[".tabs-overview {\n  width: 100%;\n}\n\n.tab-content {\n  display: block;\n  width: 100%;\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
