var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./TableHeader.css";
/**
 * TableHeader component for table column headers
 */
var TableHeader = function (_a) {
    var title = _a.title, _b = _a.width, width = _b === void 0 ? "auto" : _b, _c = _a.style, style = _c === void 0 ? {} : _c;
    var headerStyle = __assign({ width: width }, style);
    return (_jsx("th", __assign({ className: "table-header", style: headerStyle }, { children: title })));
};
export default TableHeader;
