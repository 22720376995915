// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row td {
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  vertical-align: top;
}

.table-row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.02);
}

.table-row:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.response-text {
  margin-right: 0.5rem;
}

.response-value {
  color: #666;
  font-size: 0.9em;
}

/* Responsive styles */
@media (max-width: 768px) {
  .table-row td {
    padding: 0.75rem 0.5rem;
    font-size: 0.9rem;
  }
  
  .response-value {
    font-size: 0.8em;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/molecules/obligationsTableRow/ObligationsTableRow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA,sBAAsB;AACtB;EACE;IACE,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".table-row td {\n  padding: 1rem;\n  border-bottom: 1px solid #e0e0e0;\n  border-right: 1px solid #e0e0e0;\n  vertical-align: top;\n}\n\n.table-row:nth-child(even) {\n  background-color: rgba(0, 0, 0, 0.02);\n}\n\n.table-row:hover {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\n.response-text {\n  margin-right: 0.5rem;\n}\n\n.response-value {\n  color: #666;\n  font-size: 0.9em;\n}\n\n/* Responsive styles */\n@media (max-width: 768px) {\n  .table-row td {\n    padding: 0.75rem 0.5rem;\n    font-size: 0.9rem;\n  }\n  \n  .response-value {\n    font-size: 0.8em;\n  }\n} "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
