var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import axios from 'axios';
import { useState } from 'react';
import Button from '../../atoms/button/button';
import InputField from '../../molecules/inputField/inputField';
export default function ContactForm(props) {
    var urlParams = new URLSearchParams(window.location.search);
    var submitted = urlParams.get('submitted');
    var error = urlParams.get('error');
    var _a = useState({
        firstName: '',
        lastName: '',
        email: '',
        body: '',
    }), formData = _a[0], setFormData = _a[1];
    var _b = useState({}), errors = _b[0], setErrors = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    function handleChange(e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[name] = value, _a)));
        if (name === 'email') {
            validateEmail(value);
        }
    }
    function validateEmail(email) {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!re.test(email) || email === '') {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { email: 'Please enter a valid email address' })); });
        }
        else {
            setErrors(function (prev) { return (__assign(__assign({}, prev), { email: undefined })); });
        }
    }
    function validateForm() {
        var newErrors = {};
        var isValid = true;
        if (formData.firstName.trim() === '') {
            newErrors.firstName = 'First name is required';
            isValid = false;
        }
        if (formData.lastName.trim() === '') {
            newErrors.lastName = 'Last name is required';
            isValid = false;
        }
        if (formData.email.trim() === '' || errors.email) {
            newErrors.email = 'Valid email is required';
            isValid = false;
        }
        if (formData.body.trim() === '') {
            newErrors.body = 'Message is required';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    }
    function handleSubmit() {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!validateForm()) return [3 /*break*/, 6];
                        setIsLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, axios.post('/api/contact_us/', {
                                "name": formData.firstName + ' ' + formData.lastName,
                                "email": formData.email,
                                "body": formData.body
                            })];
                    case 2:
                        _a.sent();
                        window.history.replaceState({}, document.title, window.location.pathname + '?submitted=true');
                        window.location.reload();
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        window.history.replaceState({}, document.title, window.location.pathname + '?error=true');
                        window.location.reload();
                        return [3 /*break*/, 5];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        console.log('Form has errors');
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    }
    if (submitted) {
        window.history.replaceState({}, document.title, window.location.pathname);
        return (_jsxs("div", __assign({ className: "".concat(props.className, " m-4 p-8 shadow rounded-xl") }, { children: [_jsx("h2", __assign({ className: "text-2xl font-bold text-green-600 mb-4" }, { children: "Thank you!" })), _jsx("p", __assign({ className: "text-gray-700" }, { children: "Your message has been sent successfully." })), _jsx(Button, __assign({ className: 'mt-6 w-4/5', onClick: function () { return location.reload(); } }, { children: "Send another message" }))] })));
    }
    if (error) {
        window.history.replaceState({}, document.title, window.location.pathname);
        return (_jsxs("div", __assign({ className: "".concat(props.className, " m-4 p-8 shadow rounded-xl") }, { children: [_jsx("h2", __assign({ className: "text-2xl font-bold text-red-600 mb-4" }, { children: "Oops!" })), _jsx("p", __assign({ className: "text-gray-700" }, { children: "There was an error sending your message. Please try again." })), _jsx(Button, __assign({ className: 'mt-6 w-4/5', onClick: function () { return location.reload(); } }, { children: "Try again" }))] })));
    }
    return (_jsx("div", __assign({ className: props.className }, { children: _jsx("div", __assign({ className: 'm-4 p-8 shadow rounded-xl' }, { children: _jsxs("div", __assign({ className: "space-y-4" }, { children: [_jsx(InputField, { label: "First Name", name: "firstName", value: formData.firstName, onChange: handleChange, required: true, error: errors.firstName }), _jsx(InputField, { label: "Last Name", name: "lastName", value: formData.lastName, onChange: handleChange, required: true, error: errors.lastName }), _jsx(InputField, { label: "Email", name: "email", type: "email", value: formData.email, onChange: handleChange, required: true, error: errors.email }), _jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "body", className: "block text-sm font-medium text-gray-700" }, { children: "Message" })), _jsx("textarea", { id: "body", name: "body", rows: 4, className: "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-4", value: formData.body, onChange: handleChange, required: true }), errors.body && _jsx("p", __assign({ className: "mt-1 text-sm text-red-600" }, { children: errors.body }))] }), _jsx("div", { children: _jsx(Button, __assign({ className: 'w-3/5', onClick: handleSubmit, disabled: isLoading }, { children: isLoading ? 'Sending...' : 'Submit' })) })] })) })) })));
}
