// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage {
  padding: 3% 4%;
}

.home-heading {
  color: #1e1e1e;
  font-size: 24px;
}

.home-heading-content {
  color: #777;
  margin-top: 11px;
}

.home-btn {
  width: 291px;
  height: 77px;
  box-shadow: 3px 12px 12px 0px rgba(0, 0, 0, 0.1);
}

.home-btn-m {
  margin-top: 40px;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/home.css"],"names":[],"mappings":"AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gDAAgD;AAClD;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,SAAS;AACX","sourcesContent":["@import \"./global.css\";\n\n.homepage {\n  padding: 3% 4%;\n}\n\n.home-heading {\n  color: #1e1e1e;\n  font-size: 24px;\n}\n\n.home-heading-content {\n  color: #777;\n  margin-top: 11px;\n}\n\n.home-btn {\n  width: 291px;\n  height: 77px;\n  box-shadow: 3px 12px 12px 0px rgba(0, 0, 0, 0.1);\n}\n\n.home-btn-m {\n  margin-top: 40px;\n}\n\n.home {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
