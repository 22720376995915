var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Dashboard } from "../constants/dashboard";
import { Status } from "../constants/status";
import employabilityScore from "../../assets/icons/tiles/emploability-score.svg";
import developmentAreas from "../../assets/icons/tiles/development-areas.svg";
import strengthsIcon from "../../assets/icons/tiles/strengths.svg";
import { ManageUser } from "../constants/manage_users";
var initialState = {
    status: Status.Loading,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseStats(data) {
    var strengths = [];
    var development = [];
    Object.keys(data.strengths).forEach(function (strengthKey) {
        if (data.strengths[strengthKey] &&
            data.strengths[strengthKey] !== "neither") {
            if (strengthKey == "Require childcare") {
                strengths.push("Does not Require Childcare");
            }
            else if (strengthKey == "Impact of Health") {
                strengths.push("No Impact Of Health");
            }
            else {
                strengths.push(strengthKey);
            }
        }
        else {
            development.push(strengthKey);
        }
    });
    var parsedData = [
        {
            score: data.employability_score || data["Average Final Score"],
            title: "Average Caseload Employability Score",
            linkTitle: "Based On Questionnaire",
            link: "/questionnaire",
            icon: employabilityScore,
            status: Status.Successful,
            userType: data.user_type,
        },
        {
            points: strengths,
            title: "Strengths",
            linkTitle: "View All",
            link: "/questionnaire/strengths",
            icon: strengthsIcon,
            status: Status.Successful,
            userType: data.user_type,
        },
        {
            points: development,
            title: "Development Areas",
            linkTitle: "Let's Get Started",
            link: "/questionnaire/development",
            icon: developmentAreas,
            status: Status.Successful,
            userType: data.user_type,
        },
    ];
    return parsedData;
}
var BarLabels = {
    "General Skills Score": "General Skill",
    "Job search confidence Score": ["Job Search", "Confidence"],
    "Personal attributes Score": ["Personal", "Attributes"],
    "Immediacy of employment Score": ["Immediacy", "of employment"],
    "Assets Score": "Assets",
    "Job-seeking behaviours Score": ["Job Seeking", "Behaviours"],
    "General Skills": "General Skill",
    "Job search confidence": ["Job Search", "Confidence"],
    "Personal attributes": ["Personal", "Attributes"],
    "Perceived immediacy of employment": ["Immediacy", "of employment"],
    Assets: "Assets",
    "Job-seeking behaviours": ["Job Seeking", "Behaviours"],
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseGraphData(data) {
    var parsedData = [];
    Object.keys(data.strengths_scores).forEach(function (value) {
        if (typeof data.strengths_scores[value] === "number") {
            var label = BarLabels[value];
            parsedData.push({
                label: label,
                score: data.strengths_scores[value],
            });
        }
    });
    return parsedData;
}
function parseUsername(data) {
    return data.username;
}
var parseEmpScore = function (data) {
    var parsedData = [];
    parsedData.push({
        label: ["Your Score", "Remaining Score"],
        score: data.employability_score || data["Average Final Score"],
    });
    if (data.old_employability_score) {
        parsedData.pop();
        parsedData.push({
            label: "Questionnaire 1",
            score: data.old_employability_score,
        });
        parsedData.push({
            label: "Questionnaire 2",
            score: data.employability_score,
        });
    }
    return parsedData;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseObligations(data, key) {
    // TODO: do it for Adviser Obligations once designs are out.
    var obligations = [];
    if (data[key]) {
        Object.keys(data[key]).forEach(function (value) {
            obligations.push([value, data[key][value]]);
        });
    }
    return obligations;
}
var dashboardReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case Dashboard.Update_Needs_Questionnaire:
            return __assign(__assign({}, state), { needs_questionnaire: action.payload });
        case Dashboard.Stats_Tiles:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Dashboard.Stats_Tiles_Successful:
            // eslint-disable-next-line no-case-declarations
            var o = __assign(__assign({}, state), { user_type: action.payload["user_type"], stats: parseStats(action.payload), graphData: parseGraphData(action.payload), obligations: parseObligations(action.payload, "Client obligations data"), adviserObligations: parseObligations(action.payload, "Adviser obligations data"), adviser_clientObligations: parseObligations(action.payload, "Adviser client obligations data"), adviser_adviserObligations: parseObligations(action.payload, "Adviser adviser obligations data"), score: parseEmpScore(action.payload), username: parseUsername(action.payload), status: Status.Successful, yourDashboard: true });
            return o;
        case Dashboard.Stats_Tiles_Empty:
            return __assign(__assign({}, state), { stats: [
                    {
                        status: Status.Successful,
                    },
                    {
                        status: Status.Successful,
                    },
                    {
                        status: Status.Successful,
                    },
                ], graphData: [], obligations: [], adviserObligations: [], adviser_clientObligations: [], adviser_adviserObligations: [], score: [], username: "", yourDashboard: true, user_type: "", needs_questionnaire: true, status: Status.Successful });
        case Dashboard.Stats_Tiles_Error:
            return __assign(__assign({}, state), { status: Status.Error });
        case Dashboard.Ques_Attempt_Details:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Dashboard.Ques_Attempt_Details_Successful:
            return __assign(__assign({}, state), { quesAttemptDetails: action.payload, status: Status.Successful });
        case Dashboard.Ques_Attempt_Details_Error:
            return __assign(__assign({}, state), { status: Status.Error });
        case Dashboard.Adviser_Info:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Dashboard.Adviser_Info_Successful:
            return __assign(__assign({}, state), { adviserInfo: action.payload, status: Status.Successful });
        case Dashboard.Adviser_Info_Error:
            return __assign(__assign({}, state), { status: Status.Error });
        case ManageUser.ManageUserDashboard:
            return __assign(__assign({}, state), { status: Status.Loading });
        case ManageUser.ManageUserDashboard_Sucessful:
            return __assign(__assign({}, state), { stats: parseStats(action.payload), graphData: parseGraphData(action.payload), obligations: parseObligations(action.payload, "Client obligations data"), adviserObligations: parseObligations(action.payload, "Adviser obligations data"), adviser_clientObligations: parseObligations(action.payload, "Adviser client obligations data"), adviser_adviserObligations: parseObligations(action.payload, "Adviser adviser obligations data"), score: parseEmpScore(action.payload), viewing_username: parseUsername(action.payload), logged_in_username: action.payload.logged_in_username, logged_in_user_type: action.payload.logged_in_user_type, viewing_user_type: action.payload.user_type, status: Status.Successful, yourDashboard: action.payload.logged_in_username === parseUsername(action.payload) });
        case ManageUser.ManageUserDashboard_Empty:
            return __assign({}, state);
        case Dashboard.Update_Ques_Attempt_Details:
            return __assign(__assign({}, state), { status: Status.Loading });
        case Dashboard.Update_Ques_Attempt_Details_Successful:
            return __assign(__assign({}, state), { quesAttemptDetails: action.payload, status: Status.Successful });
        case Dashboard.Update_Ques_Attempt_Details_Error:
            return __assign(__assign({}, state), { status: Status.Error });
        default:
            return state;
    }
};
export default dashboardReducer;
