/**
 * Calculate numeric value for each response
 */
export var getResponseValue = function (response) {
    var valueMap = {
        "No extent": 1,
        "Little extent": 2,
        "Some extent": 3,
        "Great extent": 4,
        "Very great extent": 5,
    };
    return valueMap[response] || 0;
};
/**
 * Calculate absolute difference between two responses
 */
export var calculateDifference = function (value1, value2) {
    var numValue1 = getResponseValue(value1);
    var numValue2 = getResponseValue(value2);
    return Math.abs(numValue1 - numValue2);
};
/**
 * Prepare data for "Your Obligations" tab
 */
export var prepareJobseekerObligationsData = function (obligations, adviser_clientObligations) {
    if (!obligations)
        return [];
    return obligations.map(function (jsItem) {
        // Find matching adviser response for job seeker obligations
        var adviserItem = adviser_clientObligations === null || adviser_clientObligations === void 0 ? void 0 : adviser_clientObligations.find(function (aItem) {
            return aItem[0].replace("adviser_", "") === jsItem[0];
        });
        // Calculate difference if both responses exist
        var difference = adviserItem
            ? calculateDifference(jsItem[1], adviserItem[1])
            : "-";
        return {
            question: jsItem[0],
            jobseekerResponse: jsItem[1],
            adviserResponse: adviserItem ? adviserItem[1] : "-",
            difference: difference,
        };
    });
};
/**
 * Prepare data for "Adviser Obligations" tab
 */
export var prepareAdviserObligationsData = function (adviserObligations, adviser_adviserObligations) {
    if (!adviserObligations)
        return [];
    return adviserObligations.map(function (jsItem) {
        // Find matching adviser response for adviser obligations
        var adviserItem = adviser_adviserObligations === null || adviser_adviserObligations === void 0 ? void 0 : adviser_adviserObligations.find(function (aItem) {
            return aItem[0].replace("adviser_", "") === jsItem[0];
        });
        // Calculate difference if both responses exist
        var difference = adviserItem
            ? calculateDifference(jsItem[1], adviserItem[1])
            : "-";
        return {
            question: jsItem[0],
            jobseekerResponse: jsItem[1],
            adviserResponse: adviserItem ? adviserItem[1] : "-",
            difference: difference,
        };
    });
};
