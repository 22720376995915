var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import "./button.css";
import "../../styles/main.css";
import { ButtonType } from "./buttonType";
var defaultProps = {
    primary: true,
    text: "Button",
    important: false,
};
// Planning to depricate the text field TODO
var mergeProps = function (props) { return (__assign(__assign({}, defaultProps), props)); };
/**
 * The Button Component.
 * Can be Primary (Primary Color), Secondary (Primary Text, Grey Background) or Important (Secondary Background).
 * This is toggled by the props
 *
 * This button can render custom TSX/JSX Components as well as rendering text. If a "children" prop is provided (of type ReactNode),
 * Then the button will display the React Nodes inside regardless of the text provided.
 * @param props
 * @returns
 */
function Button(props) {
    var _a;
    // Clicking functionality.
    var _b = useState(false), isPressed = _b[0], setPressed = _b[1];
    // Set default values since typescript does not allow them.
    var buttonProps = mergeProps(props);
    var textColor = buttonProps.primary
        ? "button-text-primary"
        : "button-text-secondary";
    var buttonType = buttonProps.primary ? "primary" : "secondary";
    // The Important flag will automatically overwrite the primary/secondary flag.
    if (buttonProps.important !== null && buttonProps.important === true) {
        buttonType = "button-important";
        textColor = "button-text-primary";
    }
    switch (props.buttonType) {
        case ButtonType.Primary:
            textColor = "button-text-primary";
            buttonType = "primary";
            break;
        case ButtonType.Secondary:
            textColor = "button-text-secondary";
            buttonType = "secondary";
            break;
        case ButtonType.Important:
            textColor = "button-text-primary";
            buttonType = "button-important";
            break;
        case ButtonType.Outlined:
            textColor = "outlined-button-text";
            buttonType = "outlined-button";
            break;
        case ButtonType.Tertiary:
            textColor = "";
            buttonType = "tertiary text-lg font-medium capitalize";
            break;
        case ButtonType.Filled:
            textColor = "outlined-button-text";
            buttonType = "filled-button";
            break;
        case ButtonType.OutlinedPrimary:
            textColor = "button-text-secondary";
            buttonType = "outlined-primary-button";
            break;
        case ButtonType.OutlinedSecondary:
            textColor = "button-text-outlined-secondary";
            buttonType = "outlined-secondary-button";
            break;
        case ButtonType.FilledBlack:
            textColor = "button-text-black";
            buttonType = "black";
            break;
    }
    // Render children if children are provided.
    // Else, render text provided inside a <p>
    var buttonChildren = typeof props.children === "string" || props.text ? (_jsx("span", __assign({ id: props.childId, className: "select-none ".concat(textColor, " ").concat(props.textClassName, " ") }, { children: (_a = props.text) !== null && _a !== void 0 ? _a : props.children }))) : (props.children);
    return (_jsx("button", __assign({ id: props.id, type: "submit", onClick: function () {
            props.onClick();
        }, onMouseDown: function () { return setPressed(true); }, onMouseUp: function () { return setPressed(false); }, className: "custom-button flex justify-center items-center ".concat(isPressed ? "pressed" : "", " ").concat(buttonType, " ").concat(props.className), disabled: buttonProps.disabled }, { children: buttonChildren })));
}
export default Button;
