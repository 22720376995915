var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TabButton from "../../atoms/tabButton/TabButton";
import "./TabNavigation.css";
/**
 * TabNavigation component for handling tab switching
 */
var TabNavigation = function (_a) {
    var tabs = _a.tabs, activeTab = _a.activeTab, onTabChange = _a.onTabChange;
    return (_jsxs("div", __assign({ className: "tab-navigation" }, { children: [_jsx("div", __assign({ className: "tab-buttons-container" }, { children: tabs.map(function (tab, index) { return (_jsx(TabButton, { label: tab.title, isActive: activeTab === index, onClick: function () { return onTabChange(index); } }, index)); }) })), _jsx("div", { className: "tabs-hr" })] })));
};
export default TabNavigation;
