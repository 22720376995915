var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TableHeader from "../../atoms/tableHeader/TableHeader";
import ObligationsTableRow from "../../molecules/obligationsTableRow/ObligationsTableRow";
import "./ObligationsTable.css";
/**
 * ObligationsTable component for displaying the full obligations table
 */
var ObligationsTable = function (_a) {
    var tableData = _a.tableData, label = _a.label, hasAdviserPerspective = _a.hasAdviserPerspective;
    return (_jsxs("div", __assign({ className: "obligations-table-container" }, { children: [_jsx("p", __assign({ className: "question matrix-heading-reduced font-medium not-italic set-line-height mb-4" }, { children: label })), !hasAdviserPerspective && (_jsx("div", __assign({ className: "adviser-perspective-alert" }, { children: _jsxs("p", { children: [_jsx("strong", { children: "Note:" }), " Adviser perspective data is not available. The \"Adviser Response\" column will show \"-\" and no difference can be calculated."] }) }))), _jsxs("table", __assign({ className: "obligations-table" }, { children: [_jsx("thead", { children: _jsxs("tr", __assign({ className: "table-header-row" }, { children: [_jsx(TableHeader, { title: "Question", width: "35%" }), _jsx(TableHeader, { title: "Job Seeker Response", width: "22%" }), _jsx(TableHeader, { title: "Adviser Response", width: "22%" }), _jsx(TableHeader, { title: "Difference", width: "21%" })] })) }), _jsx("tbody", { children: tableData && tableData.length > 0 ? (tableData.map(function (item, index) { return (_jsx(ObligationsTableRow, { question: item.question, jobseekerResponse: item.jobseekerResponse, adviserResponse: item.adviserResponse, difference: item.difference }, index)); })) : (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 4, className: "no-data-message" }, { children: "No data available" })) })) })] }))] })));
};
export default ObligationsTable;
